/*
@font-face {
    font-family: 'OTF-Pixer-Regular-otf';
    src: url('./front/OTF/Pixer-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'TTF-Pixer-Regular-ttf';
    src: url('./front/TTF/Pixer-Regular.ttf') format('opentype');
}

@font-face {
    font-family: 'Pixer-Regular-eot';
    src: url('./front/WEB/Pixer-Regular.eot') format('opentype');
}
@font-face {
    font-family: 'Pixer-Regular-woff';
    src: url('./front/WEB/Pixer-Regular.woff') format('opentype');
}
@font-face {
    font-family: 'Pixer-Regular-woff2';
    src: url('./front/WEB/Pixer-Regular.woff2') format('opentype');
}
@font-face {
    font-family: 'IBMPlexMono-Medium';
    src: url('./front/IBM-Plex-Mono/IBMPlexMono-Medium.otf') format('opentype');
}

@font-face {
   font-family: 'IBMPlexMono-Medium';
   src: url('./front/IBM-Plex-Mono/IBMPlexMono-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'IBMPlexMono-Bold';
    src: url('./front/IBM-Plex-Mono/IBMPlexMono-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'IBMPlexMono-Italic';
    src: url('./front/IBM-Plex-Mono/IBMPlexMono-Italic.otf') format('opentype');
}

@font-face {
    font-family: 'DarkStar-Bold';
    src: url('./front/DarkStar-Bold.ttf') format('opentype');
}

 */

@font-face {
    font-family: 'RobotoSlab-Black';
    font-style: normal;
    src: url('./front/Roboto_Slab/static/RobotoSlab-Black.ttf');
}

@font-face {
    font-family: 'RobotoSlab-Bold';
    font-style: normal;
    src: url('./front/Roboto_Slab/static/RobotoSlab-Bold.ttf');
}

@font-face {
    font-family: 'RobotoSlab-ExtraBold';
    font-style: normal;
    src: url('./front/Roboto_Slab/static/RobotoSlab-ExtraBold.ttf');
}

@font-face {
    font-family: 'RobotoSlab-ExtraLight';
    font-style: normal;
    src: url('./front/Roboto_Slab/static/RobotoSlab-ExtraLight.ttf');
}

@font-face {
    font-family: 'RobotoSlab-Light';
    font-style: normal;
    src: url('./front/Roboto_Slab/static/RobotoSlab-Light.ttf');
}

@font-face {
    font-family: 'RobotoSlab-Medium';
    font-style: normal;
    src: url('./front/Roboto_Slab/static/RobotoSlab-Medium.ttf');
}

@font-face {
    font-family: 'RobotoSlab-Regular';
    font-style: normal;
    src: url('./front/Roboto_Slab/static/RobotoSlab-Regular.ttf');
}

@font-face {
    font-family: 'RobotoSlab-SemiBold';
    font-style: normal;
    src: url('./front/Roboto_Slab/static/RobotoSlab-SemiBold.ttf');
}

@font-face {
    font-family: 'RobotoSlab-Thin';
    font-style: normal;
    src: url('./front/Roboto_Slab/static/RobotoSlab-Thin.ttf');
}

@font-face {
    font-family: 'SUPER-BOOM';
    font-style: normal;
    src: url('./front/Roboto_Slab/static/SUPER-BOOM.ttf');
}


@font-face {
    font-family: 'Poppins';
    src: url('./front/Poppins/Poppins-Medium.ttf') format('opentype');
}


@font-face {
    font-family: 'PoppinsR';
    src: url('./front/Poppins/Poppins-Regular.ttf') format('opentype');
}