.route {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.fade-enter {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 450ms ease-in;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}

.fade-exit {
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}


.fade-exit.fade-exit-active {
    opacity: 0;
    transition: all 450ms ease-in;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}
